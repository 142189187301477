/*
 * @Author: dengjianwen deng_jwen@163.com
 * @Date: 2023-06-25 13:22:09
 * @LastEditors: dengjianwen deng_jwen@163.com
 * @LastEditTime: 2023-07-03 07:41:57
 * @FilePath: /yingcai-culture-h5/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";

import store from "./../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "../views/Home"),
  },
  {
    path: "/article",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Article/index"),
  },
  {
    path: "/articleGroup",
    name: "articleGroup",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleGroup/index"),
  },
  {
    path: "/certificateStudent",
    name: "CertificateStudent",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CertificateStudent/index"
      ),
  },
  {
    path: "/certificateExaminer",
    name: "CertificateExaminer",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CertificateExaminer/index"
      ),
  },
  {
    path: "/certificateStudentRoyal",
    name: "CertificateStudentRoyal",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CertificateStudentRoyal/index"
      ),
  },
  {
    path: "/certificateMatch",
    name: "CertificateMatch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CertificateMatch/index"),
  },
  {
    path: "/certificateStudentApplication/:id",
    name: "CertificateStudentApplication",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CertificateStudentApplication/index"
      ),
  },
  {
    path: "/certificateStudentRoyalApplication/:id",
    name: "CertificateStudentRoyalApplication",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CertificateStudentRoyalApplication/index"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  const url = window.location.href;
  store.commit("setInitLink", url);
});

export default router;
