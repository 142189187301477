<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import wxUtils from "./utils/wxUtils";

export default {
  mounted() {
    setTimeout(() => {
      wxUtils.share({
        title: "香港皇家美育藝術教育聯合會",
        desc: "香港皇家美育藝術教育聯合會",
        link: this.$store.state.initLink,
        imgUrl:
          "https://qgyc-system.oss-cn-hangzhou.aliyuncs.com/hongkongculture/favicon.png",
      });
    }, 500);
  },
};
</script>

<style lang="less"></style>
