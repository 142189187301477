/*
 * @Author: dengjianwen deng_jwen@163.com
 * @Date: 2023-06-27 03:16:42
 * @LastEditors: dengjianwen deng_jwen@163.com
 * @LastEditTime: 2023-07-03 07:43:10
 * @FilePath: /yingcai-culture-h5/src/api/require.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引入依赖包
import axios from "axios";
// import config from "../config";
import router from "../router/index";
import { Toast } from "vant";

// 创建axios实例
const ajax = axios.create({
  baseURL: "https://hkxh.yingcaifuwu.top/api", // 因为我本地做了反向代理
  // baseURL: "/api", // 因为我本地做了反向代理
  timeout: 600000,
});

// 添加接口请求拦截器
ajax.interceptors.request.use(
  (config) => {
    // 放置发送请求前，需要修改的内容
    // 验证时候有token，有则加入请求头部
    if (sessionStorage.getItem("token")) {
      config.headers.Authorization = sessionStorage.getItem("token");
    }
    // 返回请求数据
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加接口响应拦截器
ajax.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    switch (response.data.code) {
      case 0:
        break;
      case -1:
        Toast(response.data.msg);
        break;
      case 401:
        break;
      default:
        break;
    }
    return response;
  },
  (error) => {
    // 对响应错误做点什么
    switch (error.response?.status) {
      case 401:
        router.push("/");
        break;
      case 402:
        router.push("/");
        break;
      case 500:
        break;
    }
    return Promise.reject(error);
  }
);
// 定义请求方法
export default {
  get: (url = "", data = {}) => {
    const params = {
      url,
      method: "get",
      params: data,
    };
    return ajax(params).then((response) => response.data);
  },
  post: (url = "", data = {}, options = {}) => {
    const params = {
      url,
      method: "post",
      data: data,
      ...options,
    };
    return ajax(params).then((response) => response.data);
  },
  put: (url = "", data = {}) => {
    const params = {
      url,
      method: "put",
      data: data,
    };
    return ajax(params).then((response) => response.data);
  },
  delete: (url = "", data = {}) => {
    const params = {
      url,
      method: "delete",
      data: data,
    };
    return ajax(params).then((response) => response.data);
  },
};
