import wx from "weixin-js-sdk";
import store from "./../store";
import { getWxShareConfig } from "../api/home";

export default {
  // 判断是否是微信
  isWechat() {
    const ua = window.navigator.userAgent.toLowerCase();
    const matchStr = ua.match(/micromessenger/i);
    if (matchStr && matchStr.includes("micromessenger")) return true;
    return false;
  },
  /* 初始化wxjsdk各种接口 */
  init() {
    const apiList = [
      "checkJsApi",
      "updateAppMessageShareData",
      "onMenuShareAppMessage",
      "onMenuShareTimeline",
      "updateTimelineShareData",
    ];
    //需要使用的api列表
    return new Promise((resolve, reject) => {
      console.log(store);
      getWxShareConfig({
        url: store.state.initLink,
      }).then((res) => {
        if (res.data.appId) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: apiList,
          });
          wx.ready((res) => {
            // 微信SDK准备就绪后执行的回调。
            resolve(wx, res);
          });
        } else {
          reject(res);
        }
      });
    });
  },
  // 分享方法
  async share(params) {
    if (!this.isWechat()) return;
    await this.init();
    console.log("======进来了updateAppMessageShareData=====");
    //分享给朋友及分享到QQ
    wx.updateAppMessageShareData(params);
    //分享到朋友圈及分享到QQ空间
    wx.updateTimelineShareData(params);
  },
};
